var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { isUserMasqueraded } from './utils/isUserMasqueraded';
// Allow-list loggable event types for consistency in output data
export var ELType;
(function (ELType) {
    ELType["AnonymousBrowserEvent"] = "AnonymousBrowserEvent";
    ELType["ControlledBrowserEvent"] = "ControlledBrowserEvent";
    ELType["NetworkRequestEvent"] = "NetworkRequestEvent";
})(ELType || (ELType = {}));
// Allow-list loggable journeys and events to ensure logged data always matches expected
// format and codify all possible journeys and events in one place
export var ELJourney;
(function (ELJourney) {
    ELJourney["Account"] = "account";
    ELJourney["ApplyReferral"] = "apply-referral";
    ELJourney["Authentication"] = "authentication";
    ELJourney["BalanceForecast"] = "balance-forecast";
    ELJourney["Bills"] = "bills";
    ELJourney["CarbonOffsetCalculator"] = "carbon-offset-calculator";
    ELJourney["Consumption"] = "consumption";
    ELJourney["CookiePreferences"] = "cookie-preferences";
    ELJourney["MeterReadings"] = "submit-meter-reading";
    ELJourney["MoveOut"] = "move-out";
    ELJourney["MyEnergy"] = "my-energy";
    ELJourney["Octoplus"] = "octoplus";
    ELJourney["OneOffPayment"] = "one-off-payment";
    ELJourney["Payment"] = "payment-details";
    ELJourney["PaymentsOverview"] = "payments-overview";
    ELJourney["Refund"] = "refund";
    ELJourney["SavingSessions"] = "saving-sessions";
    ELJourney["Smets2Interest"] = "smets2-interest";
    ELJourney["Smets2Booking"] = "smets2-booking";
    ELJourney["Smets2Popup"] = "smets2-popup";
    ELJourney["UpdateTariff"] = "update-tariff";
})(ELJourney || (ELJourney = {}));
export var ELControlledEvent;
(function (ELControlledEvent) {
    ELControlledEvent["Click"] = "click";
    ELControlledEvent["Pageview"] = "pageview";
    ELControlledEvent["Redirect"] = "redirect";
})(ELControlledEvent || (ELControlledEvent = {}));
export var ELAnonymousEvent;
(function (ELAnonymousEvent) {
    ELAnonymousEvent["CookiePreferenceAction"] = "set-preference-by-action";
    ELAnonymousEvent["CookiePreferenceNavigation"] = "set-preference-by-navigation";
    ELAnonymousEvent["CookiePreferenceRepeat"] = "set-repeat-preference";
})(ELAnonymousEvent || (ELAnonymousEvent = {}));
export var ELNetworkRequestEvent;
(function (ELNetworkRequestEvent) {
    ELNetworkRequestEvent["Success"] = "success";
    ELNetworkRequestEvent["NetworkError"] = "network-error";
    ELNetworkRequestEvent["RequestError"] = "request-error";
    ELNetworkRequestEvent["UnexpectedError"] = "unexpected-error";
})(ELNetworkRequestEvent || (ELNetworkRequestEvent = {}));
export const ELEvent = Object.assign(Object.assign(Object.assign({}, ELAnonymousEvent), ELControlledEvent), ELNetworkRequestEvent);
export const logAsyncEvent = (props) => __awaiter(void 0, void 0, void 0, function* () { return new Promise((resolve) => resolve(logEvent(props))); });
export const logEvent = ({ eventType = ELType.ControlledBrowserEvent, journey, event, action, accountNumber, logType = StatusType.info, }) => {
    // Manual type-checking as backup when calling from JavaScript files
    // Throw an error and skip logging if journey or event are not enumerated
    if (!Object.values(ELJourney).includes(journey)) {
        console.error(`Log rejected: "${journey}" is not an accepted journey.`);
        return;
    }
    if (!Object.values(ELEvent).includes(event)) {
        console.error(`Log rejected: "${event}" is not an accepted event.`);
        return;
    }
    // Ensure account number has been passed
    // Only check in the case of ControlledBrowserEvent
    if (eventType === ELType.ControlledBrowserEvent && !accountNumber) {
        console.error(`Log rejected: account number not provided.`);
        return;
    }
    // Ensure a valid log type
    if (!Object.values(StatusType).includes(logType)) {
        console.error(`Log rejected: "${logType}" is not a valid log type. The default is "${StatusType.info}" if unspecified.`);
        return;
    }
    datadogLogs.logger.log(`${eventType} ${journey}:${event}:${action}`, {
        // Strictly structured "controlled event" data
        controlledEvent: {
            journey,
            event,
            action,
        },
        // Customer account number for tracking
        accountNumber,
        // Masquerade flag for tracking internal use
        masquerade: isUserMasqueraded(),
        // Site brand (from Webpack config) for filtering
        brand: __BRAND__,
    }, 
    // Datadog log type, defaults to info
    logType);
};
